import { useState, useMemo } from 'react'
import { isEmpty } from 'lodash'
import ElementRow from './element_row'
import TagsWithAmountsRow from './tags_with_amounts_row'
import { COLLAPSE_EXPAND_STATES, isExpanded } from 'components/shared/collapse_expand'

const I18n = window.I18n

const RowItem = ({item, makeCopy, removeItem, updateItem, clickTagsSelectorControl, imageActions}) => {

  const { tagsAmounts, images } = item

  const [collapseExpandState, changeCollapseExpandState] = useState(
    (isEmpty(tagsAmounts) ? COLLAPSE_EXPAND_STATES.collapsed : COLLAPSE_EXPAND_STATES.expanded)
  )

  const toggleTagsRowVisibility = (newCollapseExpandState) => changeCollapseExpandState(newCollapseExpandState)

  const clickClone = () => makeCopy(item)

  const clickRemove = () => removeItem(item)

  const onChange = (e) => updateItem(item, e.target.name, e.target.value)

  const imageListUpdated = (store) => updateItemImageList(item, Array.from(store.files))

  const removeImage = (file) => removeItemImage(item, file.id)

  const clickSelect = () => clickTagsSelectorControl(item)


  //const images = Array.from(this.props.item.images.map(img => Object.assign({}, img)))

  const uploaderProps = useMemo(() => ({
    view: "ThumbnailsCollection",
    buttonId: item.key('campaign_element_photo_upload_button'),
    dropElementId: item.key('campaign_element_drop_zone'),
    modelData: {
      id: 'new',
      name: 'Campaigns::Element',
      attribute: 'photos',
    },
    filesOptions: {
      editableDescription: false,
      editableTitle: false
    },
    files: images,
    pluploadWrapperActions: {
      addNewFiles: (_, files) => imageActions.mergeItemImages(item, files), 
      fileUploaded: (_, data) => imageActions.updateItemImage (item, data)
    },
    fileActions: {
      removeFile: (imgId) => imageActions.removeItemImage(item, imgId),
      updateFile: (imgId) => imageActions.updateItemImage
    },
    maxFiles: 1,
  }), [item])

  const expandStateAction = { 
    currentState: collapseExpandState, 
    changeAction: toggleTagsRowVisibility 
  }

  return (
    <>
      <ElementRow
        item={item}
        onChange={onChange}
        clickSelect={clickSelect}
        clickClone={clickClone}
        clickRemove={clickRemove}
        uploaderProps={uploaderProps}
        expandStateAction={expandStateAction}
      />
      <TagsWithAmountsRow item={item} visible={isExpanded(collapseExpandState)} />
    </>
  )
}

export default RowItem
